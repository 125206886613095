import React from 'react';
import '../../assets/custom-nara.css'
import LoadingSpinner from "../../common/LoadingSpinner";
import {Link} from "react-router-dom";

const BreadCrumbResume = ({headerDetails}) => {
    return (
        <div className="breadcrum-area breadcrum-area-custom">
            <div className="container">
                <div className="breadcrumb">
                    <h1 className="title h2" dangerouslySetInnerHTML={{__html: headerDetails.name}}></h1>
                    <h1 className="title h5" dangerouslySetInnerHTML={{__html: headerDetails.tagline}}></h1>
                    <div>
                        <ul className="list-unstyled list-unstyled-custom">
                            {headerDetails.headline_roles.length > 0 ? (
                                headerDetails.headline_roles.map((item, index) => (
                                    <li className="active" key={index} dangerouslySetInnerHTML={{__html: item}}></li>
                                ))
                            ) : (
                                <LoadingSpinner/> // Show loading text if no data is fetched
                            )}
                        </ul>
                        <ul className="list-unstyled list-unstyled-custom">
                            <li className="active" dangerouslySetInnerHTML={{__html: headerDetails.email}}></li>
                            <li className="active" dangerouslySetInnerHTML={{__html: headerDetails.phone}}></li>
                            <li className="active" dangerouslySetInnerHTML={{__html: headerDetails.location}}></li>
                            <li className="active" dangerouslySetInnerHTML={{__html: headerDetails.web}}></li>
                            <li className="active" dangerouslySetInnerHTML={{__html: headerDetails.linkedin}}></li>
                        </ul>
                    </div>
                    <div>
                        <Link type="button" className="axil-btn btn-fill-primary download-resume-custom"
                              to={headerDetails.resume_download_url} target="_blank">
                            Download
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BreadCrumbResume;