import React from 'react';
import {Link} from 'react-router-dom';
import './PropOne.scss'; // Import the CSS file for styling
import {BlogImageExtractor} from "../../../common/DataExtractor";

const PropOne = ({projectStyle, portfolio}) => {
    // Check if portfolio data exists before rendering
    if (!portfolio || Object.keys(portfolio).length === 0) return null;

    // Extract the first image src from the portfolio description
    const imageSrc = BlogImageExtractor(portfolio.description);

    return (
        <>
            <div className={`project-grid ${projectStyle}`}>
                <div className="thumbnail">
                    <Link to={portfolio.link} className="image-container">
                        <img src={imageSrc || "/images/blog/large_thumb_5.png"} alt="icon"
                             className="thumbnail-image"/>
                        <div className="overlay"></div>
                        {/* Overlay for the image */}
                    </Link>
                </div>
                <div className="content">
					<span className="subtitle">
						{/* Check if categories exist and is an array before mapping */}
                        {Array.isArray(portfolio.categories) && portfolio.categories.map((item, index) => (
                            <a href={`https://medium.com/tag/${item}`}
                               target="_blank"
                               key={index}
                               rel="noreferrer">
                                #{item}{index < portfolio.categories.length - 1 ? ', ' : ''}
                            </a>
                        ))}
					</span>
                    <h3 className="title">
                        <Link to={portfolio.link} target={"_blank"}>
                            {portfolio.title}
                        </Link>
                    </h3>
                </div>
            </div>
        </>
    );
}

export default PropOne;
