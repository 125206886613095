import React, {useEffect, useState} from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import {fetchFirestoreData} from "../../common/DataFetcher";
import {Link} from "react-router-dom";
import LoadingSpinner from "../../common/LoadingSpinner";


const BrandOne = () => {
    const [clients, setClients] = useState([]);
    // Fetch "clients" data
    useEffect(() => {
        const unsubscribe = fetchFirestoreData('clients', setClients, 'order');
        // Clean up the listener when the component unmounts
        return () => {
            unsubscribe(); // Stop listening to Firestore changes
        };
    }, []);
    return (
        <div className="section section-padding-2 bg-color-dark">
            <div className="container">
                <SectionTitle
                    subtitle="World-renowned brands have relied on Kavindu and his teams to create outstanding products and solutions."
                    title="Trusted by Global Leaders"
                    description=""
                    textAlignment="heading-light-left"
                    textColor=""
                />
                {clients.length > 0 ? (
                    <div className='row'>
                        {clients.map((client, index) => {
                            // Check if the item is published before rendering it
                            if (client.published) {
                                return <div className="col-lg-3 col-6" key={index}>
                                    <div className="brand-grid">
                                        <a href={client.url + "?ref=narathota.com"} target="_blank" title={client.name} rel="noreferrer" >
                                            <img src={process.env.PUBLIC_URL + "/images/clients/" + client.logo}
                                                 alt={client.name}/>
                                        </a>
                                    </div>
                                </div>;
                            }
                            return null; // Return null if not published (nothing will be rendered)
                        })}
                    </div>
                ) : (
                    <LoadingSpinner/> // Show loading text if no data is fetched
                )}

                <div className="more-project-btn">
                    <Link to={process.env.PUBLIC_URL + "/summary"} className="axil-btn btn-fill-white">
                        Discover More Projects</Link>
                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1">
                    <img src={process.env.PUBLIC_URL + "/images/others/line-9.png"} alt="Circle"/>
                </li>
            </ul>
        </div>
    )
}

export default BrandOne;