import React from 'react';
import ProjectPropTwo from "./itemProp/ProjectPropTwo";
import {Link} from "react-router-dom";


const ProjectOne = ({parentClass, colSize, itemShow, columnGap, articleList}) => {
    return (
        <>
            <div className={`section section-padding-2 ${parentClass ? parentClass : ""}`}>
                <div className="container">
                    <div>
                        <p>Welcome to Nara's Journal, where I share more than just tech insights. Here, you'll find
                            personal reflections, practical how-tos, and stories on life experiences. From the latest in
                            tech to meaningful lessons, this space is all about exploring ideas and learning together.
                            Join me on this journey through #Life, #Experience, #HowTos, and, of course, #Tech!</p>
                    </div>

                    <div>
                        <div className={`row ${columnGap ? columnGap : "row-35"}`}>
                            {articleList.map((data) => (
                                <div className={colSize ? colSize : "col-md-6"} key={data.pubDate}>
                                    <ProjectPropTwo projectStyle="project-style-2" portfolio={data}/>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
                <ul className="shape-group-7 list-unstyled">
                    <li className="shape shape-1">
                        <img src={process.env.PUBLIC_URL + "/images/others/circle-2.png"} alt="circle"/>
                    </li>
                    <li className="shape shape-2">
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Line"/>
                    </li>
                    <li className="shape shape-3">
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Line"/>
                    </li>
                </ul>

                <div className="more-project-btn">
                    <Link to={process.env.REACT_APP_BLOG_SUBSCRIBE_URL} className="axil-btn btn-fill-primary"
                          target="_blank">Read more on Medium</Link>
                </div>
            </div>
        </>
    )
}

export default ProjectOne;