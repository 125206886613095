import React, {useEffect, useState} from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import ProjectPropTwo from './itemProp/ProjectPropTwo';
import {Link} from 'react-router-dom';
import {fetchAPIData} from '../../common/DataFetcher';
import LoadingSpinner from "../../common/LoadingSpinner";

const url = process.env.REACT_APP_MEDIUM_RSS_JSON;

const ProjectTwo = () => {
    const [portfolioData, setPortfolioData] = useState(null);  // Initialize as null
    const [loading, setLoading] = useState(true);  // Loading state
    const [error, setError] = useState(null);      // Error state

    useEffect(() => {
        const getData = async () => {
            try {
                const apiResult = await fetchAPIData(url);
                if (Array.isArray(apiResult.items)) {
                    setPortfolioData(apiResult.items);
                } else {
                    throw new Error("Data from API is not an array");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error.message);
            } finally {
                setLoading(false);  // Set loading to false after fetch attempt
            }
        };

        getData();
    }, []);

    if (loading) {
        return <LoadingSpinner/>;  // Show loading message
    }

    if (error) {
        return <p>Error loading projects: {error}</p>;  // Show error message if fetch fails
    }

    return (<div className="section section-padding-equal bg-color-dark">
        <div className="container">
            <SectionTitle
                subtitle="Explorations in Tech, Life, and Growth"
                title="Nara's Journal by Kavindu Narathota"
                description="Explore articles that seamlessly combine insights on technology with real-life experiences. From practical how-tos to inspiring stories, my goal is to provide valuable content that inspires and informs anyone on a journey of learning, growth, and discovery."
                textAlignment="heading-light-left"
                textColor=""
            />

            <div className="row row-45">
                {portfolioData && portfolioData.slice(0, 4).map((data) => (
                    <div className="col-md-6" key={data.title}>
                        <ProjectPropTwo projectStyle="project-style-2" portfolio={data}/>
                    </div>))}
            </div>
            <div className="more-project-btn">
                <Link to={process.env.PUBLIC_URL + "/blog"} className="axil-btn btn-fill-white">
                    Discover More Articles by Kavindu</Link>
            </div>
        </div>
    </div>)
}

export default ProjectTwo;