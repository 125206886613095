// Import necessary libraries
import axios from 'axios';
import {initializeApp} from "firebase/app";
import {addDoc, collection, getFirestore, onSnapshot, orderBy, query} from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCYRO7XfvpRfWszHcOqw0DWjFMGH4IdsJo",
    authDomain: "narathota-web-2025.firebaseapp.com",
    projectId: "narathota-web-2025",
    storageBucket: "narathota-web-2025.firebasestorage.app",
    messagingSenderId: "286058947380",
    appId: "1:286058947380:web:a87fd8f57413671338fb61",
    measurementId: "G-ZQWBKSCNYN"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app); // Initialize Firestore

// Real-time function to fetch data from Firestore
export const fetchFirestoreData = (collectionName, setDataCallback, orderByField = null) => {
    try {
        // If orderByField is provided, use it to order the data
        let dataQuery = collection(db, collectionName);

        if (orderByField) {
            dataQuery = query(dataQuery, orderBy(orderByField)); // Apply ordering
        }

        // Set up a real-time listener for the collection
        const unsubscribe = onSnapshot(
            dataQuery, // Use the query (with or without ordering)
            (snapshot) => {
                const data = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                // Pass the fetched data to the provided callback function
                setDataCallback(data);
            },
            (error) => {
                console.error(`Error fetching real-time data from Firestore collection "${collectionName}":`, error);
            }
        );

        // Return the unsubscribe function to stop listening for updates when needed
        return unsubscribe;

    } catch (error) {
        console.error(`Error setting up real-time listener for Firestore collection "${collectionName}":`, error);
        throw error;
    }
};

// Function to add data to a Firestore collection
export const uploadCSVDataToFirestore = async (collectionName, data) => {
    try {
        const collectionRef = collection(db, collectionName);
        const uploadPromises = data.map(async (item) => await addDoc(collectionRef, item));
        await Promise.all(uploadPromises);
        console.log("Data successfully uploaded to Firestore.");
    } catch (error) {
        console.error("Error uploading data to Firestore:", error);
        throw error;
    }
};

// Function to fetch data from a 3rd party API using Axios
export const fetchAPIData = async (url) => {
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error("Error fetching data from API:", error);
        throw error;
    }
};
