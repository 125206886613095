import React from 'react';
import SEO from '../common/SEO';
import BannerThree from '../component/banner/BannerThree';
import ProjectTwo from '../component/project/ProjectTwo';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import BrandOne from '../component/brand/BrandOne';
import FooterTwo from '../common/footer/FooterTwo';
import HeaderOne from "../common/header/HeaderOne";
import AboutFour from "../component/about/AboutFour";
import ScrollToTopButton from "../common/ScrollToTopButton";

const PersonalPortfolio = () => {

    return (
        <>
            <SEO
                title={`Home - ${process.env.REACT_APP_OWNER}`}
                name={process.env.REACT_APP_OWNER}
                description={process.env.REACT_APP_SEO_DESCRIPTION}
                keywords={process.env.REACT_APP_SEO_KEYWORDS}
                author={`${process.env.REACT_APP_OWNER} | ${process.env.SEO_TAG}`}
                image={process.env.REACT_APP_SEO_IMAGE}
            />
            <ColorSwitcher/>
            <ScrollToTopButton/>
            <main className="main-wrapper">
                <HeaderOne/>
                <BannerThree/>
                <AboutFour/>
                <BrandOne/>
                <ProjectTwo/>
                <FooterTwo parentStyle={"footer-dark"}/>
                <ScrollToTopButton/>
            </main>
        </>
    )
}

export default PersonalPortfolio;

