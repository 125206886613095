import React, {useEffect, useState} from 'react';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import ProjectOne from '../component/project/ProjectOne';
import {fetchAPIData} from "../common/DataFetcher";
import FooterTwo from "../common/footer/FooterTwo";
import LoadingSpinner from "../common/LoadingSpinner";
import ScrollToTopButton from "../common/ScrollToTopButton";

const url = process.env.REACT_APP_MEDIUM_RSS_JSON;

const ProjectGridTwo = () => {
    const [portfolioData, setPortfolioData] = useState(null);  // Initialize as null
    const [loading, setLoading] = useState(true);  // Loading state
    const [error, setError] = useState(null);      // Error state

    useEffect(() => {
        const getData = async () => {
            try {
                const apiResult = await fetchAPIData(url);
                console.log(apiResult.items);

                if (Array.isArray(apiResult.items)) {
                    setPortfolioData(apiResult.items);
                } else {
                    throw new Error("Data from API is not an array");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(error.message);
            } finally {
                setLoading(false);  // Set loading to false after fetch attempt
            }
        };

        getData();
    }, []);

    const headerImageSrc = "/images/banner/nara-blog-thumbnail.png";

    return (
        <>
            <SEO
                title={`Blog - ${process.env.REACT_APP_OWNER}`}
                name={process.env.REACT_APP_OWNER}
                description={process.env.REACT_APP_SEO_DESCRIPTION}
                keywords={process.env.REACT_APP_SEO_KEYWORDS}
                author={`${process.env.REACT_APP_OWNER} | ${process.env.SEO_TAG}`}
                image={process.env.REACT_APP_SEO_IMAGE}
            />
            <ColorSwitcher/>
            <ScrollToTopButton/>
            <main className="main-wrapper">
                <HeaderOne/>
                <div>
                    <BcrumbBannerOne
                        title="Nara's Journal"
                        paragraph="by Kavindu Narathota"
                        styleClass=""
                        mainThumb={headerImageSrc || "/images/banner/banner-thumb-1.png"}/>
                </div>
                {/* Render the content or loading spinner as appropriate */}
                {loading ? (
                    <LoadingSpinner/>
                ) : error ? (
                    <p>Error loading blogs: {error}</p>
                ) : (
                    <ProjectOne colSize="col-xl-4 col-md-6" columnGap="row-15" articleList={portfolioData}/>
                )}
                <FooterTwo/>
            </main>
        </>
    )
}

export default ProjectGridTwo;