import React, {useState, useEffect} from 'react';
import {FaArrowAltCircleUp} from "react-icons/fa";
import "../assets/custom-nara.css"

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsVisible(window.scrollY > window.innerHeight);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        isVisible && (
            <button
                className={`scroll-button ${isHovered ? "hovered" : ""}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
                <FaArrowAltCircleUp />
            </button>
        )
    );
};

export default ScrollToTopButton;
