// DataExtractor.js

/**
 * Extracts the first image src from an HTML string.
 * @param {string} description - The HTML string containing the description.
 * @returns {string|null} - The src attribute of the first img tag, or null if none found.
 */
export function BlogImageExtractor(description) {
    // Create a DOM parser
    const parser = new DOMParser();

    // Parse the HTML string into a document
    const doc = parser.parseFromString(description, 'text/html');

    // Find the first img tag
    const img = doc.querySelector('img');

    // Return the src attribute if img tag exists, else null
    return img ? img.src : null;
}
