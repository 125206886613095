import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import CountUp from 'react-countup';
import TrackVisibility from 'react-on-screen';
import {TypeAnimation} from "react-type-animation";
import {
    FaAmazon,
    FaCoffee,
    FaFacebookF,
    FaFile,
    FaFlickr,
    FaGithub,
    FaInstagram,
    FaLinkedin,
    FaYoutube
} from "react-icons/fa";
import {FaMedium, FaUserGroup, FaXTwitter} from "react-icons/fa6";
import {fetchFirestoreData} from "../../common/DataFetcher";
import LoadingSpinner from "../../common/LoadingSpinner";

const AboutFour = () => {
    const [aboutData, setAboutData] = useState([]);
    const [socialData, setSocialData] = useState([]);
    const [aboutStats, setAboutStats] = useState([]);

    // Fetch "about_me" data
    useEffect(() => {
        const unsubscribe = fetchFirestoreData('about_me', setAboutData);

        // Clean up the listener when the component unmounts
        return () => {
            unsubscribe(); // Stop listening to Firestore changes
        };
    }, []);
    // Fetch "social_links" data
    useEffect(() => {
        const unsubscribe = fetchFirestoreData('social_links', setSocialData, 'order');

        // Clean up the listener when the component unmounts
        return () => {
            unsubscribe(); // Stop listening to Firestore changes
        };
    }, []);
    // Fetch "about_stats" data
    useEffect(() => {
        const unsubscribe = fetchFirestoreData('about_stats', setAboutStats, 'order');

        // Clean up the listener when the component unmounts
        return () => {
            unsubscribe(); // Stop listening to Firestore changes
        };
    }, []);

    // Function to get the corresponding icon for each social link
    const getIcon = (title) => {
        switch (title) {
            case 'Facebook':
                return <FaFacebookF/>;
            case 'Twitter':
                return <FaXTwitter/>;
            case 'LinkedIn':
                return <FaLinkedin/>;
            case 'Instagram':
                return <FaInstagram/>;
            case 'YouTube':
                return <FaYoutube/>;
            case 'Medium':
                return <FaMedium/>;
            case 'GitHub':
                return <FaGithub/>;
            case 'BuyMeACoffee':
                return <FaCoffee/>;
            case 'Flickr':
                return <FaFlickr/>;
            case 'Amazon':
                return <FaAmazon/>;
            case 'Notion':
                return <FaFile/>;
            default:
                return <FaUserGroup/>;
        }
    };

    return (
        <div className="section section-padding case-study-featured-area">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="case-study-featured">
                            <div className="section-heading heading-left">
                                <span className="subtitle">Who is {process.env.REACT_APP_OWNER.split(" ")[0]}</span>
                                <div>
                                    {aboutData.length > 0 ? (
                                        aboutData.map((item, index) => (
                                            <div key={index}>
                                                <div>
                                                    <h2 className="title">
                                                        <TypeAnimation
                                                            sequence={
                                                                item.highlights && item.highlights.length > 0
                                                                    ? item.highlights.flatMap((highlight) => [highlight, 2000]) // Add 1200ms timeout after each highlight
                                                                    : [
                                                                        'a Software Product Strategist', 2000,
                                                                        'a Software Program Specialist', 2000,
                                                                        'a Former Lead Software Engineer', 2000,
                                                                        'a Certified ScrumMaster®', 2000
                                                                    ]
                                                            }
                                                            wrapper="span"
                                                            repeat={Infinity}
                                                        />
                                                    </h2>
                                                </div>
                                                {item.about_text.length > 0 ? (
                                                    item.about_text.map((item2, index2) => (
                                                        <div key={index2} dangerouslySetInnerHTML={{__html: item2}}/>
                                                    ))
                                                ) : (
                                                    <LoadingSpinner/> // Show loading text if no data is fetched
                                                )}
                                            </div>
                                        ))
                                    ) : (
                                        <LoadingSpinner/> // Show loading text if no data is fetched
                                    )}
                                </div>
                            </div>
                            <div className="case-study-counterup">
                                {aboutStats.length > 0 ? (
                                    aboutStats.map((item, index) => (
                                        <div className="single-counterup" key={index}>
                                            <h2 className="count-number">
                                                <TrackVisibility once>
                                                    {({isVisible}) => (
                                                        <span className="number count">
                                                    {isVisible ? <CountUp end={item.value} duration={1}/> : null}
                                                </span>
                                                    )}
                                                </TrackVisibility>
                                                <span className="symbol">+</span>
                                            </h2>
                                            <span className="counter-title">{item.description}</span>
                                        </div>
                                    ))
                                ) : (
                                    <LoadingSpinner/> // Show loading text if no data is fetched
                                )}
                            </div>
                        </div>
                        <div className="footer-top">
                            <div className="footer-social-link">
                                <div className="list-unstyled">
                                    {socialData.length > 0 ? (
                                        <ul className="list-unstyled">
                                            {socialData.map((item, index) => {
                                                // Check if the item is published before rendering it
                                                if (item.published) {
                                                    return <li key={index}>
                                                        <Link to={item.url} target="_blank"
                                                              title={item.title}>{getIcon(item.icon)}</Link>
                                                    </li>;
                                                }
                                                return null; // Return null if not published (nothing will be rendered)

                                            })}
                                        </ul>
                                    ) : (
                                        <LoadingSpinner/> // Show loading text if no data is fetched
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AboutFour;