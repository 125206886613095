import React from 'react';
import {Link} from 'react-router-dom';
import {FaLinkedin} from "react-icons/fa";
import {FaMedium} from "react-icons/fa6";


const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li><Link to={process.env.PUBLIC_URL + "/blog"}>Blog</Link></li>
                <li><Link to={process.env.PUBLIC_URL + "/summary"}>Professional Highlights</Link></li>
                <li><Link to={process.env.PUBLIC_URL + "/resume"}>Resume</Link></li>
                {/*<li><Link to={process.env.PUBLIC_URL + "/contact"}>PM Resources</Link></li>*/}
                <li><a href="https://blog.narathota.com/?ref=narathota.com" target="_blank" rel="noreferrer"><FaMedium/></a></li>
                <li><a href="https://www.linkedin.com/in/narathota/?ref=narathota.com" rel="noreferrer"><FaLinkedin/></a></li>
            </ul>
        </nav>
    )
}

export default Nav;